import { useQuery } from '@tanstack/react-query';
import { DropdownChangeParams } from 'primereact/dropdown';
import DropdownStyled from '../../../components/inputs/StyledInputs/DropdownStyled';
import { useCompanyContext } from '../../../context/CompanyContext';
import { JCPhase } from '../../../Interfaces/Accounting/JobsContracts.interface';
import { fetchJCJobPhases } from '../../../services/JobsContractsService';

type PhaseCodeInputProps = {
  value: string;
  onChange: (e: DropdownChangeParams) => void;
  job: string;
};

const PhaseCodeInput = ({ value, onChange, job }: PhaseCodeInputProps) => {
  const { selectedCompany } = useCompanyContext();

  const phases = useQuery({
    queryKey: ['getJCJobPhases', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJCJobPhases(
        {
          companyID: selectedCompany!.id,
          job,
          costTypes: [1],
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const selectedInfo = phases.data?.find((job: JCPhase) => job.Phase === value);

  return (
    <span className="h-full w-full">
      <DropdownStyled
        options={phases.data}
        labelField="Label"
        valueField="Phase"
        isLoading={phases.isLoading}
        error={phases.isError}
        className="h-full printHide"
        filter={true}
        value={value}
        onChange={onChange}
      />
      <span className="printMedia printShow">{selectedInfo?.Label}</span>
    </span>
  );
};

export default PhaseCodeInput;
