import React from 'react';

export const isJSONObject = (obj: unknown): boolean => {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    !Array.isArray(obj) &&
    obj.constructor === Object
  );
};

export const jsonsComparison = (
  json1: Record<string, unknown>,
  json2: Record<string, unknown>
) => {
  if (Object.keys(json1).length !== Object.keys(json2).length) {
    return false;
  }

  let result = true;
  for (const i in json1) {
    const isArray = Array.isArray(json1[i]) && Array.isArray(json2[i]);

    if (isArray) {
      result =
        result && arrayComparison(json1[i] as unknown[], json2[i] as unknown[]);
    } else if (json1[i] !== json2[i]) {
      result = false;
    }
  }

  return result;
};

export const arrayComparison = (array1: unknown[], array2: unknown[]) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};

export const Comparison = (object1: unknown, object2: unknown) => {
  const type1 = typeof object1;
  const type2 = typeof object2;

  if (type1 === 'object' && type2 === 'object') {
    const isArray = Array.isArray(object1) && Array.isArray(object2);

    if (isArray) {
      return arrayComparison(object1, object2);
    }

    const isJson = isJSONObject(object1) && isJSONObject(object2);
    if (isJson) {
      return jsonsComparison(
        object1 as Record<string, unknown>,
        object2 as Record<string, unknown>
      );
    }
  }

  if (type1 === 'function' && type2 === 'function') {
    const func1 = object1 as Function;
    const type2 = object2 as Function;

    return func1.toString() === type2.toString();
  }

  return object1 === object2;
};

export const compareReactElements = (
  prevChild: React.ReactElement,
  nextChild: React.ReactElement
): boolean => {
  let isEquals = true;

  const prevElement = prevChild as React.ReactElement;
  const nextElement = nextChild as React.ReactElement;

  if (prevElement.key === nextElement.key) {
    const prevProps = Object.entries(prevElement.props);
    const nextProps = Object.entries(nextElement.props);

    if (prevProps.length !== nextProps.length) {
      isEquals = false;
    } else {
      for (let prevEntry of prevProps) {
        const [key, prevValue] = prevEntry;
        const nextValue = nextElement.props[key];

        if (
          React.isValidElement(prevValue) &&
          React.isValidElement(nextValue)
        ) {
          isEquals =
            isEquals &&
            compareReactElements(prevValue as React.ReactElement, nextValue);
        } else {
          isEquals = isEquals && Comparison(prevValue, nextValue);
        }
      }
    }
  } else {
    isEquals = false;
  }

  return isEquals;
};
