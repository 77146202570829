import { useQuery } from '@tanstack/react-query';
import DropdownStyled from 'apps/tmr-frontend/src/app/components/inputs/StyledInputs/DropdownStyled';
import { useCompanyContext } from 'apps/tmr-frontend/src/app/context/CompanyContext';
import { Employee as EmployeeType } from 'apps/tmr-frontend/src/app/Interfaces/Accounting/Payroll.interface';
import { fetchPREmployeeList } from 'apps/tmr-frontend/src/app/services/PayrollService';

type EmployeeProps = {
  value: number;
  changeValue: (emp?: number, firstName?: string, lastName?: string) => void;
  disableFetch?: boolean;
};

const Employee = ({ value, changeValue, disableFetch }: EmployeeProps) => {
  const { selectedCompany } = useCompanyContext();

  const empRequest = useQuery({
    queryKey: ['getPRActiveEmployees', selectedCompany],
    queryFn: ({ signal }) => {
      return fetchPREmployeeList(
        { companyID: selectedCompany!.id, status: true },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany && !disableFetch,
  });

  const empItems =
    empRequest.data?.map((emp: EmployeeType) => {
      return { ...emp, label: `${emp.EmpID} ${emp.FirstName} ${emp.LastName}` };
    }) ?? [];

  return (
    <span className="w-full text-center h-full">
      <DropdownStyled
        options={empItems}
        labelField="label"
        valueField="EmpID"
        isLoading={empRequest.isFetching}
        error={empRequest.isError}
        className="h-full my-auto printHide"
        filter={true}
        value={value}
        onChange={(e) => {
          const prevEmp = empRequest.data.find(
            (emp: EmployeeType) => emp.EmpID === value
          );

          if (prevEmp) {
            delete prevEmp.disabled;
          }

          if (e.target.value) {
            const selectedEmp = empRequest.data.find(
              (emp: EmployeeType) => emp.EmpID === e.target.value
            );

            selectedEmp.disabled = true;

            changeValue(
              e.target.value,
              selectedEmp.FirstName,
              selectedEmp.LastName
            );
          } else {
            changeValue();
          }
        }}
      />
      <span className="printMedia printShow">{value}</span>
    </span>
  );
};

export default Employee;
