import { Controller, useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import { CompanySource } from '../../Interfaces/User.interfaces';

export type CompaniesFilterArgs = {
  source?: CompanySource;
  info?: string;
};

const CompaniesFilter = () => {
  const { control } = useFormContext();

  return (
    <div className="flex flex-wrap justify-content-center align-items-end md:justify-content-start gap-4 form">
      <div className="w-15rem">
        <div className="text-standard mb-2">Source</div>
        <Controller
          control={control}
          name="source"
          render={({ field: { onChange, ...otherFields } }) => (
            <DropdownStyled
              options={Object.values(CompanySource)}
              clearable={true}
              placeholder="Select Source"
              onChange={(e) => {
                onChange(e);
              }}
              {...otherFields}
            />
          )}
        />
      </div>
      <div className="w-15rem align-self-end">
        <Controller
          control={control}
          name="info"
          defaultValue=""
          render={({ field }) => (
            <span className="p-input-icon-right">
              <InputText
                type="text"
                placeholder="Search"
                className="fieldBorder w-full text-standard blackText"
                {...field}
              />
              <i className="pi pi-search" />
            </span>
          )}
        />
      </div>
    </div>
  );
};

export default CompaniesFilter;
