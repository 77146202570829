import { useEffect, useRef, useState } from 'react';
import { Location, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCookies } from 'react-cookie';
import { useIdleTimer } from 'react-idle-timer';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const cookiesObjectsList = useCookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isIdle, setIsIdle] = useState(false);
  const location = useLocation();
  const hasRendered = useRef(false);

  useIdleTimer({
    timeout: parseInt(process.env['NX_LOG_OUT_TIME'] ?? '0'),
    onIdle: () => {
      setIsIdle(true);
    },
    crossTab: true,
  });

  const logOut = (location: Location) => {
    const userUID = auth.currentUser?.uid;
    signOut(auth);
    const cookies = cookiesObjectsList[0];
    const removeCookie = cookiesObjectsList[2];
    Object.keys(cookies).forEach((key) => {
      removeCookie(key);
    });

    navigate('/login', { state: { from: location, cookies, userUID } });
  };

  useEffect(() => {
    if (isIdle) {
      logOut(location);
    }
  }, [isIdle, location, logOut]);

  useEffect(() => {
    if (!hasRendered.current) {
      hasRendered.current = true;

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    }

    return;
  }, []);

  if (loading) {
    return (
      <div className="h-screen flex align-items-center">
        <ProgressSpinner />
      </div>
    );
  }

  if (!authenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
