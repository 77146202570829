import { FormProvider, useForm } from 'react-hook-form';
import LoginForm, { LoginFormData } from '../features/login/LoginForm';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorToast, { showToast } from '../components/messages/ErrorAlert';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import firebaseErrorMessages from '../components/errorMessages/firebase';
import { useCookies } from 'react-cookie';

const Login = () => {
  const toast = useRef<Toast>(null);
  const cookiesObjectsList = useCookies();
  const formMethods = useForm<LoginFormData>();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSubmit } = formMethods;
  const { mutate, isLoading } = useMutation({
    mutationFn: (data: LoginFormData) => {
      return signInWithEmailAndPassword(auth, data.email, data.password);
    },
    onSuccess: (data) => {
      const cookies = location.state?.cookies ?? {};
      const userUID = location.state?.userUID;
      const { pathname, search } = location.state?.from ?? {};
      const from = pathname ? `${pathname}${search ?? ''}` : '/';

      if (userUID === data.user.uid) {
        const setCookie = cookiesObjectsList[1];
        Object.entries(cookies).forEach(([string, value]) => {
          const expirationTime = new Date(Date.now() + 60 * 60 * 1000);

          setCookie(string, value, { expires: expirationTime, path: '/' });
        });
      }

      navigate(from, { replace: true });
    },
    onError: (error: { message: string; code: string }) => {
      showToast(
        'error',
        toast,
        'Login Error',
        firebaseErrorMessages[error.code] ||
          'An error ocurred, please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: LoginFormData) => {
    mutate(data);
  };

  return (
    <div
      className="login-page h-screen flex"
      style={{
        backgroundImage: 'url(assets/layout/images/login-background.svg)',
      }}
    >
      <div className="flex align-items-center m-auto justify-content-center">
        <div className="p-4">
          <div className="text-center mb-8">
            <img
              src="./assets/layout/images/bluwai_Logo.png"
              alt="Bluwai"
              className="w-full"
            />
          </div>
          <ErrorToast toastRef={toast} />
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LoginForm />
              <Button className="w-15rem buttonSecondary border-0 flex m-auto py-3">
                {isLoading && <ProgressSpinner className="w-3rem h-3rem" />}
                {!isLoading && (
                  <span className="mx-auto text-22px text-white">Log In</span>
                )}
              </Button>
              <Link
                to="/resetPassword"
                className="mt-2 block text-standard totalColor cursor-pointer hover:underline text-center"
              >
                Forgot Password?
              </Link>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Login;
