import { useQuery } from '@tanstack/react-query';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchPayrollBonuses } from '../../../services/PayrollService';
import { getSubmittedDraft } from '../PettyCashReimbursement/utils';
import { PayrollBonusFilters } from './BonusFilters';

const BonusReportHeader = () => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<PayrollBonusFilters>();

  const submittedBonuses = useQuery({
    queryKey: ['getSubmittedPayrollBonuses', selectedCompany],
    queryFn: ({ signal }) =>
      fetchPayrollBonuses(
        {
          companyID: selectedCompany!.id,
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Payroll Bonus</div>
      <div className="text-17px ml-3 mt-2">
        {filters.submittedID && (
          <div>
            <span className="font-bold mr-1">Submitted Draft:</span>
            {getSubmittedDraft(filters.submittedID, submittedBonuses.data)}
          </div>
        )}
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default BonusReportHeader;
