import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import WrapperButton from '../../../components/buttons/WrapperButton';
import OptionCard from '../../../components/cards/OptionCard';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { postFolder } from '../../../services/FinancialStatementsService';
import FolderForm, { FolderFormFields } from './FolderForm';

type NewFolderOptionProps = {
  afterCreate?: () => void;
};

const NewFolderOption = ({ afterCreate }: NewFolderOptionProps) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);
  const [visible, setVisible] = useState(false);
  const formMethods = useForm<FolderFormFields>();
  const { handleSubmit } = formMethods;

  const { mutate, isLoading, isSuccess, reset } = useMutation({
    mutationFn: (folderName: string) => {
      return postFolder(
        selectedCompany!.id,
        selectedCompany!.source,
        folderName
      );
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Add Folder Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onHide = () => {
    setVisible(false);
    formMethods.reset();
    if (afterCreate && isSuccess) {
      afterCreate();
    }
  };

  const onClick = () => {
    setVisible(true);
  };

  const onSubmit = (data: FolderFormFields) => {
    const { folderName } = data;
    mutate(folderName);
  };

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible, reset]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <WrapperButton
        className="w-15rem h-13rem text-center text-standard"
        onClick={onClick}
      >
        <OptionCard>
          <div className="h-8rem flex">
            <i className="pi pi-plus-circle text-70px m-auto text-gray-700" />
          </div>
          <div>Add new folder</div>
        </OptionCard>
      </WrapperButton>
      <Dialog header="Add new folder" visible={visible} onHide={onHide}>
        {!isSuccess && (
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="max-w-624px mx-auto flex flex-column gap-5"
            >
              <FolderForm />
              <div className="flex flex-wrap gap-2 mb-3">
                <Button
                  type="button"
                  onClick={onHide}
                  className="w-10rem bluwaiRed border-0 flex m-auto py-3"
                >
                  <span className="mx-auto text-18px text-white">Cancel</span>
                </Button>
                <LoadingButton isLoading={isLoading} label="Add" />
              </div>
            </form>
          </FormProvider>
        )}
        {isSuccess && (
          <SuccessFormMessage message={`The folder has been added!`} />
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default NewFolderOption;
