import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ResumeCard from '../../components/cards/ResumeCard';
import ErrorMessage from '../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../context/CompanyContext';
import { JCContractResume } from '../../Interfaces/Accounting/JobsContracts.interface';
import { fetchAccountingDashboardContracts } from '../../services/DataService';
import JCResume from '../jobsContracts/JCResume/JCResume';

const DashboardJobsAndContracts = () => {
  const { selectedCompany } = useCompanyContext();
  const [contracts, setContracts] = useState<JCContractResume[]>([]);
  const flaggedContracts = contracts.filter(
    (contract) => contract.PJTDProfit < 10 || contract.NetCashFlow < 0
  );

  const { data, isError } = useQuery({
    queryKey: ['getAccountingDashboardContractsData', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountingDashboardContracts(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (data?.jobContracts) {
      setContracts(data.jobContracts);
    }
  }, [data]);

  if (isError) {
    return (
      <ResumeCard>
        <div className="relative p-5 px-4 flex flex-column w-full">
          <Link to={`/accounting-dashboard/jobs-contracts`} className="w-fit">
            <div className="text-24px font-bold cursor-pointer totalColor hover:underline w-fit">
              Jobs and Contracts
            </div>
          </Link>
          <div className="my-8 py-3">
            <ErrorMessage
              content={'Failed to obtain data! Please try again later.'}
            />
          </div>
        </div>
      </ResumeCard>
    );
  }

  return (
    <ResumeCard>
      <div className="p-3 px-4 flex flex-column">
        <Link to={`/accounting-dashboard/jobs-contracts`} className="w-fit">
          <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
            Jobs and Contracts
          </div>
        </Link>
        <div className="pt-2 text-17px">
          {!flaggedContracts.length ? 'Active Jobs' : 'Flagged Jobs'}
        </div>
        <div className="pt-2">
          <JCResume
            contracts={contracts}
            defaultSize={202}
            defaultActiveJobs={true}
          />
        </div>
      </div>
    </ResumeCard>
  );
};

export default DashboardJobsAndContracts;
