import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fecthCBActiveContractsInfo } from '../../../services/ContractBillingsService';
import CBDrilldownContent from './CBDrilldownContent';

type CBDrilldownContainerProps = {
  visible: boolean;
  hideDrilldown: () => void;
  state?: string;
  period: string;
};

const CBDrilldownContainer = ({
  visible,
  hideDrilldown,
  state,
  period,
}: CBDrilldownContainerProps) => {
  const { selectedCompany } = useCompanyContext();
  const isEnabled = selectedCompany;
  const { data, isFetching, isError, isSuccess } = useQuery({
    queryKey: ['getARActiveContractInfo', selectedCompany, state, period],
    queryFn: ({ signal }) =>
      fecthCBActiveContractsInfo(
        { companyID: selectedCompany!.id, state, period },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={hideDrilldown}
      className="w-full max-w-1000px"
    >
      {isFetching && (
        <div className="mx-8 h-full flex align-items-center">
          <ProgressSpinner />
        </div>
      )}
      {isError && !isFetching && (
        <div className="h-full flex align-items-center">
          <ErrorMessage
            content={'Failed to obtain data! Please try again later.'}
          />
        </div>
      )}
      {isSuccess && !isFetching && (
        <CBDrilldownContent
          state={state}
          period={period}
          contracts={data}
          redirect={state === 'pending'}
          hideDrilldown={hideDrilldown}
        />
      )}
    </Sidebar>
  );
};

export default CBDrilldownContainer;
