import React, { useCallback, useRef } from 'react';
import Table from '../../../components/Table/Table';
import { Column } from 'primereact/column';
import { formatCurrency } from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { ContractResume } from '../../../Interfaces/Accounting/ContractBillings.interface';
import CBDrilldownReport from '../CBReport/CBDrilldownReport';
import { useCompanyContext } from '../../../context/CompanyContext';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';

type CBDrilldownContentProps = {
  contracts: ContractResume[];
  state?: string;
  period: string;
  redirect?: boolean;
  hideDrilldown: () => void;
};

const CBDrilldownContent = ({
  contracts,
  state,
  period,
  redirect,
  hideDrilldown,
}: CBDrilldownContentProps) => {
  const { selectedCompany } = useCompanyContext();
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const buildLink = (contract: string) => {
    const secretKey = process.env['NX_CRYPTO_PUBLIC_KEY'] ?? '';

    const cipherCompany = CryptoJS.AES.encrypt(
      selectedCompany!.id,
      secretKey
    ).toString();
    const cipherContract = CryptoJS.AES.encrypt(contract, secretKey).toString();

    const encodContract = encodeURIComponent(cipherContract);
    const encodComp = encodeURIComponent(cipherCompany);

    const url = `company=${encodComp}&reportType=contractProgress&contract=${encodContract}`;
    return url;
  };

  let contractAmnt = 0;
  let billed = 0;
  let pending = 0;
  let retainage = 0;
  contracts.forEach((contract: ContractResume) => {
    contractAmnt = contractAmnt + contract.ContractAmount;
    billed = billed + contract.BilledAmount;
    pending = pending + contract.PendingAmount;
    retainage = retainage + contract.Retainage;
  });

  return (
    <div
      id="cb-drilldown"
      ref={drilldownRef}
      className="blackText text-standard printBackground max-w-1000px"
    >
      <div className="text-28px text-center font-bold">
        {selectedCompany!.name}
      </div>
      <div className="mt-3 flex flex-column sm:row-gap-2 sm:flex-row flex-wrap align-items-center justify-content-center sm:justify-content-end">
        <div className="text-25px font-bold mx-auto sm:ml-4 drilldownHeader capitalize">
          <div> {state} Contracts</div>
          <div className="text-20px">Billing Cycle: {period}</div>
        </div>
        <div className="text-20px sm:ml-4 sm:mr-6 mt-2 sm:mt-0 cursor-pointer printHide">
          <CBDrilldownReport
            elementRef={drilldownRef}
            state={state}
            period={period}
          />
        </div>
      </div>
      <div className="my-4" />
      <Table
        data={contracts}
        className="mt-5"
        stripedRows={false}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          field="Contract"
          header="Contract #"
          body={(cb) => {
            if (redirect) {
              return (
                <Link
                  to={`/accounting-dashboard/contract-billings?${buildLink(
                    cb.Contract
                  )}`}
                  onClick={hideDrilldown}
                  className="flex gap-1 align-items-center"
                  target="_self"
                  rel="noopener noreferrer"
                >
                  <div className="totalColor cursor-pointer hover:underline">
                    {cb.Contract}
                  </div>
                </Link>
              );
            }

            return cb.Contract;
          }}
          headerClassName={`font-normal bg-white pb-0`}
          style={{ minWidth: '120px', maxWidth: '120px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
        <Column
          field="ContractName"
          header="Contract Name"
          body={(cb) => {
            if (redirect) {
              return (
                <Link
                  to={`/accounting-dashboard/contract-billings?${buildLink(
                    cb.Contract
                  )}`}
                  onClick={hideDrilldown}
                  className="flex gap-1 align-items-center"
                  target="_self"
                  rel="noopener noreferrer"
                >
                  <div className="scroll-text totalColor cursor-pointer hover:underline">
                    {cb.ContractName}
                  </div>
                </Link>
              );
            }

            return <div className="scroll-text">{cb.ContractName}</div>;
          }}
          headerClassName={`font-normal bg-white pb-0 justify-content-center`}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footer="Total"
          footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
        />
        <Column
          field="ContractAmount"
          header="Contract Amnt"
          body={(cb) => formatCurrency(cb.ContractAmount)}
          headerClassName={`font-normal bg-white pb-0 justify-content-center`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          footer={formatCurrency(contractAmnt)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
        />
        <Column
          field="BilledAmount"
          header="Billed to Date"
          body={(cb) => formatCurrency(cb.BilledAmount)}
          headerClassName={`font-normal bg-white pb-0 justify-content-center`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          footer={formatCurrency(billed)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
        />
        <Column
          field="PendingAmount"
          header="Unbilled"
          body={(cb) => formatCurrency(cb.PendingAmount)}
          headerClassName={`font-normal bg-white pb-0 justify-content-center`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          footer={formatCurrency(pending)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
        />
        <Column
          field="Retainage"
          header="Retainage"
          body={(cb) => formatCurrency(cb.Retainage)}
          headerClassName={`font-normal bg-white pb-0 justify-content-center`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          footer={formatCurrency(retainage)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
        />
      </Table>
    </div>
  );
};

export default CBDrilldownContent;
