import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  FileUploadProps,
  FileUploadRemoveParams,
  FileUploadSelectParams,
  ItemTemplateOptions,
} from 'primereact/fileupload';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ErrorToast, { showToast } from '../../messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import FileInputHeader from './../FileComponents/FileInputHeader';
import FileInputItem from './../FileComponents/FileInputItem';
import { getFilesTotalSize } from 'apps/tmr-frontend/src/utils/fileUtil';

export type MultipleFileInputProps = FileUploadProps & {
  acceptedFormats: string[];
  contentClassName?: string;
  value: File[];
  onChange: (files: File[] | null) => void;
  totalLimit: number;
  totalLimitLabel: string;
  emptyMessage?: string;
};

const MultipleFileInput = ({
  acceptedFormats,
  contentClassName,
  value,
  onChange,
  totalLimit,
  totalLimitLabel,
  emptyMessage,
  ...otherProps
}: MultipleFileInputProps) => {
  const fileUploadRef = useRef<FileUpload>(null);
  const [sizeTotal, setSizeTotal] = useState(getFilesTotalSize(value ?? []));
  const [fileNames, setFileNames] = useState<string[]>(
    value?.map((file) => file.name) ?? []
  );
  const toast = useRef<Toast>(null);
  const setDefault = useRef<boolean>(!!value?.length);

  const onSelect = (data: FileUploadSelectParams) => {
    const length = data.files.length;
    let startSize = sizeTotal;

    const startList = [...(value || [])];
    const filesOverTheLimit = [];
    for (let i = 0; i < length; i++) {
      const selFile = data.files[i];
      const repeated = startList.filter(
        (file: File) =>
          file.name === selFile.name &&
          file.size === selFile.size &&
          file.type === selFile.type &&
          file.lastModified === selFile.lastModified
      );

      if (startSize + selFile.size <= totalLimit && repeated.length === 0) {
        startSize += selFile.size;
        startList.push(selFile);
      } else if (startSize + selFile.size > totalLimit) {
        filesOverTheLimit.push(selFile.name);
      }
    }

    if (setDefault.current) {
      setDefault.current = false;
      let list = new DataTransfer();
      startList.forEach((file) => list.items.add(file));
      (data.originalEvent.target as any).files = list.files;
      fileUploadRef.current?.onFileSelect(data.originalEvent as any);

      return;
    }

    setSizeTotal(startSize);
    onChange(startList);
    setFileNames(startList.map((file) => file.name));

    if (filesOverTheLimit.length > 0) {
      showToast(
        'error',
        toast,
        'Upload File Error',
        `The following files are over the limit: ${filesOverTheLimit.join(
          ', '
        )}`,
        5000
      );
    }
  };

  const onRemove = (removedFile: File) => {
    const newList = value?.filter(
      (file: File) =>
        !(
          removedFile.name === file.name &&
          removedFile.size === file.size &&
          removedFile.type === file.type &&
          removedFile.lastModified === file.lastModified
        )
    );

    onChange(newList?.length > 0 ? newList : null);
    setSizeTotal(sizeTotal - removedFile.size);
    setFileNames(newList?.map((file: File) => file.name) || []);

    if (!(sizeTotal - removedFile.size)) {
      fileUploadRef.current?.clear();
    }
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    return (
      <FileInputHeader
        headerOptions={options}
        currentSize={sizeTotal}
        sizeLimitLabel={totalLimitLabel}
        sizelimit={totalLimit}
      />
    );
  };

  const itemTemplate = (file: File, props: ItemTemplateOptions) => {
    const isInValue = fileNames.includes(file.name);

    return isInValue && <FileInputItem file={file} onRemove={props.onRemove} />;
  };

  useEffect(() => {
    if (!value || value?.length === 0) {
      fileUploadRef.current?.clear();
      setSizeTotal(0);
      setFileNames([]);
    }
  }, [value]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <FileUpload
        ref={fileUploadRef}
        accept={acceptedFormats.join(',')}
        contentClassName={contentClassName}
        multiple={true}
        headerTemplate={useCallback(headerTemplate, [
          sizeTotal,
          totalLimit,
          totalLimitLabel,
        ])}
        itemTemplate={(file, props) => itemTemplate(file as File, props)}
        emptyTemplate={
          value?.length ? (
            <React.Fragment>
              <div
                role="progressbar"
                className="p-progressbar p-component p-progressbar-determinate"
                aria-valuemin={0}
                aria-valuenow={0}
                aria-valuemax={100}
              >
                <div
                  className="p-progressbar-value p-progressbar-value-animate"
                  style={{ width: '0%', display: 'flex' }}
                />
              </div>
              <div className="p-fileupload-files">
                {value.map((file) => (
                  <div className="p-fileupload-row">
                    <FileInputItem
                      key={file.name}
                      file={file}
                      onRemove={() => {
                        onRemove(file);
                      }}
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : (
            <p className="m-0">
              {emptyMessage ?? (
                <React.Fragment>
                  <span className="mr-1">
                    Drag and drop files to upload. Allowed extensions:
                  </span>
                  {acceptedFormats.join(', ')}
                </React.Fragment>
              )}
            </p>
          )
        }
        cancelOptions={{ className: 'hidden' }}
        uploadOptions={{ className: 'hidden' }}
        onSelect={useCallback(onSelect, [
          onChange,
          sizeTotal,
          value,
          totalLimit,
        ])}
        onRemove={useCallback(
          (data: FileUploadRemoveParams) => onRemove(data.file),
          [onChange, value, sizeTotal]
        )}
        {...otherProps}
      />
    </React.Fragment>
  );
};

export default MultipleFileInput;
