import { Column } from 'primereact/column';
import { useCallback } from 'react';
import Table from '../../../components/Table/Table';
import { PCReimbursementDetails } from '../../../Interfaces/Accounting/Payroll.interface';
import Amount from './Fields/Amount';
import GLAccount from './Fields/GLAccount';
import Job from './Fields/Job';
import Phase from './Fields/Phase';

type PCReimbursementTableDetailsProps = {
  details: PCReimbursementDetails[];
  empID: number;
  showErrors: boolean;
  addNewDetail: (empID: number) => void;
  removeDetail: (empID: number, detailIndex: number) => void;
  updateDetails: (
    empID: number,
    detailIndex: number,
    detail: PCReimbursementDetails
  ) => void;
  searchBonus?: boolean;
};

const PCReimbursementTableDetails = ({
  details,
  empID,
  addNewDetail,
  removeDetail,
  updateDetails,
  showErrors,
  searchBonus,
}: PCReimbursementTableDetailsProps) => {
  const getRowClassname = (data: PCReimbursementDetails) => {
    if (!data.amount && !data.job && !data.phaseCode && !data.overHead) {
      return 'printHide';
    }

    return '';
  };

  return (
    <Table
      data={details}
      className={`expandedTable noFooter w-full ${showErrors}`}
      scrollable={false}
      scrollHeight={undefined}
      rowClassName={useCallback(getRowClassname, [])}
    >
      <Column
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="justify-content-center text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={() => {
          return <span className="visibility-hidden">details</span>;
        }}
        style={{ minWidth: '180px' }}
        headerClassName="hidden"
        className=" text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '160px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="amount"
        body={(row, options) => {
          return (
            <Amount
              value={row.amount}
              changeValue={(amount) => {
                const rowCopy = { ...row };
                if (amount) {
                  rowCopy.amount = amount;
                } else {
                  delete rowCopy.amount;
                }

                updateDetails(empID, options.rowIndex, rowCopy);
              }}
            />
          );
        }}
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="job"
        body={(row, options) => {
          return (
            <Job
              value={row.job}
              disableFetch={!!options.rowIndex}
              showErrors={showErrors && !(row.job || row.overHead)}
              disabled={!!row.overHead}
              changeValue={(job) => {
                const rowCopy = { ...row };
                delete rowCopy.phaseCode;

                if (job) {
                  rowCopy.job = job;
                } else {
                  delete rowCopy.job;
                }

                updateDetails(empID, options.rowIndex, rowCopy);
              }}
            />
          );
        }}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="phaseCode"
        body={(row, options) => {
          const job = row.job;

          if (!job) {
            return;
          }

          return (
            <Phase
              searchBonus={searchBonus}
              value={row.phaseCode}
              job={row.job}
              showErrors={showErrors && row.job && !row.phaseCode}
              changeValue={(phaseCode) => {
                const rowCopy = { ...row };
                rowCopy.phaseCode = phaseCode;
                updateDetails(empID, options.rowIndex, rowCopy);
              }}
            />
          );
        }}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="overHead"
        body={(row, options) => {
          return (
            <GLAccount
              searchBonus={searchBonus}
              value={row.overHead}
              disableFetch={!!options.rowIndex}
              disabled={!!row.job}
              showErrors={showErrors && !(row.job || row.overHead)}
              changeValue={(overHead) => {
                const rowCopy = { ...row };

                if (overHead) {
                  rowCopy.overHead = overHead;
                } else {
                  delete rowCopy.overHead;
                }

                updateDetails(empID, options.rowIndex, rowCopy);
              }}
            />
          );
        }}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center expandedTableCell`}
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          if (options.rowIndex + 1 === details.length) {
            return (
              <i
                className="pi pi-plus text-17px cursor-pointer"
                onClick={() => {
                  addNewDetail(empID);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center expandedTableCell`}
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          if (details.length !== 1) {
            return (
              <i
                className="pi pi-trash text-17px cursor-pointer"
                onClick={() => {
                  removeDetail(empID, options.rowIndex);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center expandedTableCell`}
        footerClassName="tableFooter"
      />
    </Table>
  );
};

export default PCReimbursementTableDetails;
