export const FORMS = [
  {
    section: 'Accounts Payables',
    forms: [
      { label: 'New Vendor Setup' },
      { label: 'Vendor Credit Application' },
      { label: 'AP Invoice/ Statements Dropoff' },
      { label: 'Check Request' },
    ],
  },
  {
    section: 'Accounts Receivables',
    forms: [{ label: 'New Customer' }, { label: 'Prequalification' }],
  },
  {
    section: 'Contract Billings',
    forms: [
      {
        label: 'New Progress Billing',
        url: '/accounting-dashboard/contract-billings?reportType=contractProgress',
        access: 'contract_progress_worksheet',
      },
      { label: 'New Invoice' },
    ],
  },
  {
    section: 'Jobs & Contracts',
    access: [
      'new_contract_setup',
      'schedule_of_values',
      'job_phase_codes',
      'estimated_cost',
      'po_entry',
      'change_orders',
    ],
    forms: [
      {
        label: 'New Contract Setup',
        url: 'new_contract_setup',
        access: 'new_contract_setup',
      },
      {
        label: 'Schedule of Values',
        url: 'schedule_of_values',
        access: 'schedule_of_values',
      },
      {
        label: 'Add Job Phase Codes',
        url: 'job_phases',
        access: 'job_phase_codes',
      },
      {
        label: 'Estimated Cost',
        url: 'estimated_cost',
        access: 'estimated_cost',
      },
      { label: 'PO Entry', url: 'po_entry', access: 'po_entry' },
      { label: 'Change Orders', url: 'change_orders', access: 'change_orders' },
    ],
  },
  {
    section: 'Payroll',
    access: ['new_pr_timesheet', 'petty_cash_reimbursement', 'bonus'],
    forms: [
      {
        label: 'New PR Timesheet',
        url: 'payroll_timesheet',
        access: 'new_pr_timesheet',
      },
      {
        label: 'Petty Cash Reimbursement',
        url: 'petty_cash_reimbursement',
        access: 'petty_cash_reimbursement',
      },
      {
        label: 'Bonus',
        url: 'bonus',
        access: 'bonus',
      },
    ],
  },
  {
    section: 'Subcontracts',
    access: ['new_subcontract_setup', 'subcontract_payment_worksheet'],
    forms: [
      {
        label: 'New Subcontract Setup',
        url: 'subcontract_setup',
        access: 'new_subcontract_setup',
      },
      {
        label: 'New Subcontract Payment',
        url: '/accounting-dashboard/subcontracts?reportType=paymentWorksheet',
        access: 'subcontract_payment_worksheet',
      },
    ],
  },
];

export const JOB_PHYSICIANS_OPTIONS = ['Confirmed'];

export const JOB_NOTICE_OPTIONS = [
  { label: 'Florida-Yes', value: 'Florida-Yes' },
  { label: 'NC-Yes', value: 'NC-Yes' },
  { label: 'Other States-N/A', value: 'OtherStates-N/A' },
];

export const CONTRACT_FORM_FIELDS = {
  0: [
    'contract',
    'contractName',
    'department',
    'customer',
    'retainage',
    'processGroup',
    'startDate',
  ],
  1: [
    'projectManager',
    'prState',
    'taxCode',
    'reviewerGroup',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'county',
    'physician',
    'notice',
  ],
  2: [
    'bondCost',
    'bondPercentage',
    'bondReqd',
    'dateOrdered',
    'insurance',
    'insuranceDateOrdered',
    'contractValue',
    'supervision',
    'targetCost',
    'estProfit',
    'estProfitPercentage',
    'estCostMarkUp',
    'jobType',
    'certified',
    'ccip',
    'dwellingUnits',
    'metric1',
    'metric1Amt',
    'metric2',
    'metric2Amt',
    'heated',
    'parking',
    'fieldPS',
  ],
} as const;

export const EXCEMPTED_DEPARTMENT_COMPANIES = ['90'];

export const PO_FORM_FIELDS = {
  0: [
    'job',
    'poType',
    'vendor',
    'poDate',
    'month',
    'multipleYN',
    'identifier',
    'description',
    'poID',
  ],
  1: ['pieceWork'],
} as const;

export const PO_COST_TYPES: Record<string, string> = {
  Subcontractor: 'subcontractor',
  'Mat Vendor': 'material',
};

export const SL_FORM_FIELDS = {
  0: ['job', 'vendor', 'description', 'sl', 'upType'],
} as const;

export const UM_ONLY_TOTAL = ['LS'];

export const NEW_CONTRACT_FIELDS_TO_IGNORE = [
  'startDate',
  'bondCost',
  'bondPercentage',
  'bondReqd',
  'dateOrdered',
  'insurance',
  'insuranceDateOrdered',
  'supervision',
  'targetCost',
  'estProfit',
  'estProfitPercentage',
  'estCostMarkUp',
  'contractValue',
];
