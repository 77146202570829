import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import CompaniesFilter, {
  CompaniesFilterArgs,
} from '../../../features/companies/CompaniesFilter';
import CompaniesTableContainer from '../../../features/companies/CompaniesTableContainer';

const Companies = () => {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const methods = useForm<CompaniesFilterArgs>();

  return (
    <div className="pt-5 mx-3">
      <FormProvider {...methods}>
        <ErrorToast toastRef={toast} />
        <div className="mb-8 flex justify-content-between">
          <div className="font-bold text-35px">Companies</div>
          <Button
            onClick={() => {
              navigate('register');
            }}
            className="ml-5 h-fit buttonBackground text-white border-0 text-xl w-fit py-3 px-3 radius-10px"
          >
            New Company
          </Button>
        </div>
        <div className="flex flex-wrap gap-3 mb-3">
          <CompaniesFilter />
        </div>
        <CompaniesTableContainer />
      </FormProvider>
    </div>
  );
};

export default Companies;
