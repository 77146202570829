import { useQuery } from '@tanstack/react-query';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchPayrollBonuses } from '../../../services/PayrollService';
import { PCReimbursementSummary } from '../../../Interfaces/Accounting/Payroll.interface';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import React from 'react';

export type PayrollBonusFilters = {
  submittedID?: string;
};

const BonusFilters = () => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess } = useRolesAccessContext();
  const canApprove = rolesAcess?.find(
    (access) => access.report === 'bonus' && access.shouldApprove
  );

  const submittedPayrollBonuses = useQuery({
    queryKey: ['getSubmittedPayrollBonuses', selectedCompany],
    queryFn: ({ signal }) =>
      fetchPayrollBonuses(
        {
          companyID: selectedCompany!.id,
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && canApprove),
    staleTime: 1000 * 60 * 10,
    cacheTime: 0,
  });

  return (
    <React.Fragment>
      {canApprove && (
        <div className="col">
          <FormControlledDropdown
            formID="submittedID"
            options={
              submittedPayrollBonuses.data?.map(
                (draft: PCReimbursementSummary) => {
                  return {
                    ...draft,
                    label: `${formatUTCDate(draft.updatedAt || '')} - ${
                      draft.userName
                    }`,
                  };
                }
              ) || []
            }
            labelField="label"
            valueField="id"
            placeholder={
              submittedPayrollBonuses.isError
                ? 'Failed to load submitted drafts!'
                : 'Approve Submitted Drafts'
            }
            isDisabled={
              submittedPayrollBonuses.isError ||
              !submittedPayrollBonuses.data?.length
            }
            isLoading={submittedPayrollBonuses.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default BonusFilters;
