import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';
import FormControlledNumberInput from '../../../components/inputs/FormInputs/FormControlledNumberInput';

import { CostType } from '../../../Interfaces/Accounting/JobsContracts.interface';
import { fetchARCustomers } from '../../../services/AccountsReceivablesService';
import {
  fecthCostTypes,
  fecthJobs,
  fetchJCJobPhases,
} from '../../../services/JobsContractsService';
import { fetchUnitsOfMeasure } from '../../../services/ACFormsService';
import {
  COFormFields,
  UnitOfMeasure,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import SingleFileInput from '../../../components/inputs/StyledInputs/SingleFileInput';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  EXCEL_FORMATS,
  transformBToMB,
  WORD_FORMATS,
} from 'apps/tmr-frontend/src/utils/fileUtil';
import { Button } from 'primereact/button';
import PhaseRow from './PhaseRow';
import { FILE_20_MB_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';

const COInfo = () => {
  const { selectedCompany } = useCompanyContext();
  const {
    control,
    formState: { errors },
  } = useFormContext<COFormFields>();
  const [ums, setUms] = useState<Record<string, string>>({});
  const [costTypes, setCostTypes] = useState<Record<number, string>>({});
  const job = useWatch({ name: 'job' });
  const { fields, remove, append, replace } = useFieldArray<COFormFields>({
    control,
    name: 'phases',
  });

  useQuery({
    queryKey: ['getUnitOfMeasure'],
    queryFn: ({ signal }) => {
      return fetchUnitsOfMeasure(signal);
    },
    onSuccess: (data) => {
      const umsJson: Record<string, string> = {};

      data.forEach((um: UnitOfMeasure) => {
        umsJson[um.UM] = um.Description;
      });

      setUms(umsJson);
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ['getCostTypes', selectedCompany],
    queryFn: ({ signal }) => {
      return fecthCostTypes(selectedCompany!.id, signal);
    },
    onSuccess: (data) => {
      const ctsJson: Record<number, string> = {};

      data.forEach((ct: CostType) => {
        ctsJson[ct.CostType] = ct.Description;
      });

      setCostTypes(ctsJson);
    },
    refetchOnWindowFocus: false,
  });

  const customers = useQuery({
    queryKey: ['getCOCustomer', selectedCompany],
    queryFn: ({ signal }) => fetchARCustomers(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const jobs = useQuery({
    queryKey: ['getCOJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  useQuery({
    queryKey: ['getJCJobPhases', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJCJobPhases(
        {
          companyID: selectedCompany!.id,
          job,
          hasCost: false,
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job),
  });

  useEffect(() => {
    replace([]);
  }, [job]);

  return (
    <div>
      <div className="grid mx-3 justify-content-center">
        <div className="col-12 sm:col-6">
          <label htmlFor="customer" className="text-standard block mb-2">
            Customer
          </label>
          <FormControlledDropdown
            formID="customer"
            options={customers.data}
            labelField="name"
            valueField="accountNumber"
            placeholder={
              customers.isError
                ? 'Failed to load customers!'
                : 'Select Customer'
            }
            isDisabled={customers.isError}
            isLoading={customers.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Customer is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6" />
        <div className="col-12 sm:col-6">
          <label htmlFor="job" className="text-standard block mb-2">
            Job #
          </label>
          <FormControlledDropdown
            formID="job"
            options={jobs.data}
            labelField="Job"
            valueField="Job"
            placeholder={jobs.isError ? 'Failed to load jobs!' : 'Select Job'}
            isDisabled={jobs.isError}
            isLoading={jobs.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Job is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="job" className="text-standard block mb-2">
            Job Name
          </label>
          <FormControlledDropdown
            formID="job"
            options={jobs.data}
            labelField="Description"
            valueField="Job"
            placeholder={
              jobs.isError ? 'Failed to load job names!' : 'Select Job Name'
            }
            isDisabled={jobs.isError}
            isLoading={jobs.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Job Name is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="changeOrder" className="text-standard block mb-2">
            Approved CO #
          </label>
          <FormControlledInput
            defaultValue=""
            formID="changeOrder"
            rules={{
              required: {
                value: true,
                message: 'Approved CO # is a required field',
              },
            }}
            autocomplete="off"
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="description" className="text-standard block mb-2">
            CO Description
          </label>
          <FormControlledInput
            defaultValue=""
            formID="description"
            rules={{
              required: {
                value: true,
                message: 'CO Description is a required field',
              },
            }}
            autocomplete="off"
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="amount" className="text-standard block mb-2">
            CO Amount
          </label>
          <FormControlledNumberInput
            formID="amount"
            min={0}
            mode="currency"
            currency="USD"
            locale="en-US"
            rules={{
              required: {
                value: true,
                message: 'Amount is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6" />
        <div className="col-12 flex gap-3 align-content-center">
          <label htmlFor="changeOrder" className="text-standard h-fit my-auto">
            Phases
          </label>
          <Button
            className="mr-auto text-17px py-2 px-3 font-bold"
            onClick={() =>
              append({
                description: '',
                code: null,
              })
            }
            type="button"
          >
            +
          </Button>
        </div>
        {fields?.map((field, index) => {
          return (
            <PhaseRow
              key={field.id}
              fieldId={field.id}
              fieldIndex={index}
              job={job}
              remove={remove}
              costTypes={costTypes}
              ums={ums}
            />
          );
        })}
        <div className="col-12">
          <label htmlFor="phase" className="text-standard block mb-2">
            Attach Executed CO
          </label>
          <Controller
            name="file"
            aria-label="file-input"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Executed CO is a required field',
              },
            }}
            render={({ field: { onChange, value } }) => (
              <div>
                <SingleFileInput
                  value={value}
                  onChange={onChange}
                  acceptedFormats={[...EXCEL_FORMATS, ...WORD_FORMATS, '.pdf']}
                  totalLimit={FILE_20_MB_LIMIT}
                  totalLimitLabel={`${transformBToMB(FILE_20_MB_LIMIT)} MB`}
                  emptyMessage={`Upload Executed CO file. Allowed extensions: ${[
                    ...EXCEL_FORMATS,
                    ...WORD_FORMATS,
                    '.pdf',
                  ].join(', ')}.`}
                  contentClassName={errors['file'] && 'border-red-500'}
                />
                {errors['file'] && (
                  <FormErrorMessage
                    errorMessage={errors['file'].message as string}
                  />
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default COInfo;
