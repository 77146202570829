import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '../../../components/inputs/LoadingButton';
import CompanyForm, {
  CompanyFormFields,
} from '../../../features/companies/CompanyForm';
import { postCompany } from '../../../services/UsersService';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';

const CompanyRegister = () => {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const formMethods = useForm<CompanyFormFields>();
  const { handleSubmit } = formMethods;

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: CompanyFormFields) => {
      return postCompany(data);
    },

    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Register Company Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: CompanyFormFields) => {
    mutate(data);
  };

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-8">
      <ErrorToast toastRef={toast} />
      <div className="text-35px font-bold text-center mb-5">
        Register Company
      </div>
      {!isSuccess && (
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-600px mx-auto flex flex-column gap-5"
          >
            <CompanyForm />
            <div className="flex flex-wrap gap-2 mb-3">
              <Button
                onClick={() => {
                  navigate('/admin/companies');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
              <LoadingButton
                isLoading={isLoading}
                label="Register"
                className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                fontSize="text-22px"
              />
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && (
        <SuccessFormMessage
          message="The company has been created!"
          redirectUrl="/admin/companies"
          redirectMessage="Click here to return to the companies page"
        />
      )}
    </div>
  );
};

export default CompanyRegister;
