import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import { FolderSummary } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import { putFolder } from '../../../services/FinancialStatementsService';
import FolderForm, { FolderFormFields } from '../NewFolder/FolderForm';

type FolderUpdateProps = {
  folder: FolderSummary;
  visible: boolean;
  onHide: () => void;
  fetchFolders?: () => void;
};

const FolderUpdate = ({
  folder,
  visible,
  onHide,
  fetchFolders,
}: FolderUpdateProps) => {
  const toast = useRef<Toast>(null);
  const formMethods = useForm<FolderFormFields>({
    defaultValues: { folderName: folder.name },
  });
  const { handleSubmit } = formMethods;

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (folderName: string) => {
      return putFolder(folder.id, folderName);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Edit Folder Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: FolderFormFields) => {
    const { folderName } = data;
    mutate(folderName);
  };

  const hideDialog = () => {
    if (isSuccess && fetchFolders) {
      fetchFolders();
    }

    onHide();
  };

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <Dialog header="Edit folder" visible={visible} onHide={hideDialog}>
        {!isSuccess && (
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="max-w-624px mx-auto flex flex-column gap-5"
            >
              <FolderForm />
              <div className="flex flex-wrap gap-2 mb-3">
                <Button
                  type="button"
                  onClick={onHide}
                  className="w-10rem bluwaiRed border-0 flex m-auto py-3"
                >
                  <span className="mx-auto text-18px text-white">Cancel</span>
                </Button>
                <LoadingButton isLoading={isLoading} label="Update" />
              </div>
            </form>
          </FormProvider>
        )}
        {isSuccess && (
          <SuccessFormMessage message={`The folder has been updated!`} />
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default FolderUpdate;
