import axios from 'axios';
import { auth } from '../../firebase';
import { CompanySource } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'];

export const postFolder = async (
  companyID: string,
  source: CompanySource,
  folderName: string
) => {
  const response = await axios.post(
    `${apiUrl}/api/financialStatements`,
    { companyID, source, folderName },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const getFoldersSummary = async (
  companyID: string,
  source: CompanySource,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/financialStatements`, {
    params: { companyID, source },
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const getFolder = async (
  companyID: string,
  source: CompanySource,
  folderPath: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/financialStatements/${folderPath}`,
    {
      params: { companyID, source },
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putFolder = async (folderID: string, folderName: string) => {
  const response = await axios.put(
    `${apiUrl}/api/financialStatements/${folderID}`,
    { folderName },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const deleteFolder = async (folderID: string) => {
  const response = await axios.delete(
    `${apiUrl}/api/financialStatements/${folderID}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postFile = async (folderID: string, formData: FormData) => {
  const response = await axios.post(
    `${apiUrl}/api/financialStatements/${folderID}/files`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const getFolderFiles = async (
  folderID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/financialStatements/${folderID}/files`,
    {
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const deleteFile = async (fileID: string, folderID: string) => {
  const response = await axios.delete(
    `${apiUrl}/api/financialStatements/${folderID}/files/${fileID}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const downloadFile = async (
  fileID: string,
  folderID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/financialStatements/${folderID}/files/${fileID}/download`,
    {
      signal,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response;
};
