import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import { JobPhasesFormFields } from '../features/acForms/JobPhases/JobPhasesForm';
import {
  NewContractFields,
  POArgs,
  SLArgs,
} from '../Interfaces/Accounting/ACForms.interfaces';
import { Company } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'];

export const fetchContractLookUpInfo = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/acconting_forms/contractInfo`,
    {
      signal,
      params: { companyID },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchJobLookUpInfo = async (
  companyID: string,
  department: number,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/acconting_forms/jobInfo`, {
    signal,
    params: { companyID, department },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchNewContract = async (
  companyID: string,
  department?: number,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/acconting_forms/newContract`,
    {
      signal,
      params: { companyID, department },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchContractGeneralInfo = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/acconting_forms/contractGeneralInfo`,
    {
      signal,
      params: { companyID },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postNewContract = async (
  companyID: string,
  body: NewContractFields
) => {
  const { attachments, ...otherFields } = body;
  const formData = new FormData();
  attachments?.forEach((file) => {
    formData.append('attachments', file);
  });

  const cleanBody = cleanJsonEmptyValues(otherFields);
  for (const key of Object.keys(cleanBody)) {
    const value = cleanBody[key];
    formData.append(
      key,
      value instanceof Date ? value.toISOString() : String(value)
    );
  }
  formData.append('companyID', companyID);

  const response = await axios.post(
    `${apiUrl}/api/acconting_forms/newContract`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postScheduleOfValues = async (
  companyID: string,
  schedule: File[]
) => {
  const formData = new FormData();
  schedule.forEach((file) => {
    formData.append('schedules', file);
  });
  formData.append('companyID', companyID);

  const response = await axios.post(
    `${apiUrl}/api/schedule_of_values/newSchedule`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postEstimatedCosts = async (
  companyID: string,
  phases?: File,
  estimates?: File
) => {
  const formData = new FormData();

  if (phases) {
    formData.append('phases', phases);
  }

  if (estimates) {
    formData.append('estimates', estimates);
  }

  formData.append('companyID', companyID);

  const response = await axios.post(
    `${apiUrl}/api/estimated_cost/newEstimates`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchPhaseMasterFile = async (companyID: string) => {
  const response = await axios.get(`${apiUrl}/api/estimated_cost/phaseMaster`, {
    params: { companyID },
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response;
};

export const fetchPOCheck = async (
  companyID: string,
  po: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/purchase_order/checkPO`, {
    signal,
    params: { companyID, po },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const result = response.data;
  return result;
};

export const fetchUnitsOfMeasure = async (signal?: AbortSignal) => {
  const response = await axios.get(
    `${apiUrl}/api/purchase_order/unitsOfMeasure`,
    {
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const fetchJobCostCodes = async (
  companyID: string,
  job: string,
  costType?: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/purchase_order/costCodes`, {
    signal,
    params: { companyID, job, costType },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const result = response.data;
  return result;
};

export const fetchPhaseMasterCostCodes = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/phaseMaster/costCodes`,
    {
      signal,
      params: { companyID },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const fetchJobExtras = async (
  companyID: string,
  job: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/purchase_order/extras`, {
    signal,
    params: { companyID, job },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const result = response.data;
  return result;
};

export const postNewPO = async (body: POArgs) => {
  const { poFile, companyID, ...payload } = body;
  const cleanBody = cleanJsonEmptyValues(payload);

  const response = await axios.post(
    `${apiUrl}/api/purchase_order/newPO`,
    { ...cleanBody, companyID, poFile },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export type PostChangeOrdersArgs = {
  customer: number;
  job: string;
  changeOrder: string;
  description: string;
  amount: number;
  phases?: {
    code: string;
    description?: string;
    details?: { costType: number; estimate: number }[];
  }[];
};

export const postChangeOrders = async (
  company: Company,
  body: PostChangeOrdersArgs,
  file: File
) => {
  const cleanBody = cleanJsonEmptyValues(body);

  const response = await axios.post(
    `${apiUrl}/api/change_order/newCO`,
    { ...cleanBody, companyID: company.id, source: company.source, file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const postPhaseCodes = async (
  company: Company,
  body: JobPhasesFormFields
) => {
  const cleanBody = cleanJsonEmptyValues(body);

  const response = await axios.post(
    `${apiUrl}/api/estimated_cost/newPhases`,
    { ...cleanBody, companyID: company.id },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const fetchSLCheck = async (
  companyID: string,
  sl: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/subcontract/checkSL`, {
    signal,
    params: { companyID, sl },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const result = response.data;
  return result;
};

export const postNewSL = async (companyID: string, body: SLArgs) => {
  const { subFile, attachment, ...payload } = body;
  const cleanBody = cleanJsonEmptyValues(payload);

  const response = await axios.post(
    `${apiUrl}/api/subcontract/new`,
    { ...cleanBody, companyID, subFile, attachment },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};
