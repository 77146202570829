import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FiltersMenu from '../../../components/filters-menu/FiltersMenu';
import BonusFilters, {
  PayrollBonusFilters,
} from '../../../features/acForms/PayrollBonus/BonusFilters';
import BonusParams from '../../../features/acForms/PayrollBonus/BonusParams';
import BonusReport from '../../../features/acForms/PayrollBonus/BonusReport';
import BonusReportHeader from '../../../features/acForms/PayrollBonus/BonusReportHeader';
import PayrollBonusContainer from '../../../features/acForms/PayrollBonus/PayrollBonusContainer';
import PCReimbursementParams from '../../../features/acForms/PettyCashReimbursement/PCReimbursementParams';

const PayrollBonus = () => {
  const methods = useForm<PayrollBonusFilters>();
  const tableRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="flex flex-column justify-content-center gap-1 pt-4">
      <div className="text-35px font-bold text-center mb-2">Payroll Bonus</div>
      <FormProvider {...methods}>
        <div className="mt-5 px-3 flex w-full justify-content-between">
          <FiltersMenu>
            <BonusFilters />
          </FiltersMenu>
          <BonusReport elementRef={tableRef} />
        </div>
        <div className="mt-1 printBackground pdfDivFitContent" ref={tableRef}>
          <BonusReportHeader />
          <PayrollBonusContainer />
        </div>
        <BonusParams />
      </FormProvider>
    </div>
  );
};

export default PayrollBonus;
