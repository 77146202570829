import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { FS_File } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import { downloadFile } from '../../../services/FinancialStatementsService';
import { ProgressSpinner } from 'primereact/progressspinner';
import PreviewPDF from '../../../components/documents/PreviewPDF';
import WrapperButton from '../../../components/buttons/WrapperButton';

type PreviewFileProps = {
  file: FS_File;
  folderID: string;
};

const PreviewFile = ({ file, folderID }: PreviewFileProps) => {
  const [pdf, setPDF] = useState<string | ArrayBuffer | null>(null);
  const [show, setShow] = useState(false);
  const toast = useRef<Toast>(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return downloadFile(file.id, folderID);
    },
    onSuccess: (response) => {
      const blob = new Blob([response.data]);
      const reader = new FileReader();

      reader.onload = () => {
        const base64Data = reader.result;
        setPDF(base64Data);
        setShow(true);
      };

      reader.readAsDataURL(blob);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Preview File Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onClick = () => {
    mutate();
  };

  return (
    <div className="m-auto">
      <ErrorToast toastRef={toast} />
      {pdf && <PreviewPDF pdf={pdf} show={show} hide={() => setShow(false)} />}
      {!isLoading && (
        <WrapperButton onClick={onClick}>
          <i className="text-70px pi pi-file-pdf text-red-600 hover:bg-gray-300 border-round-xl" />
        </WrapperButton>
      )}
      {isLoading && <ProgressSpinner />}
    </div>
  );
};

export default PreviewFile;
