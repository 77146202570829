import { FILE_SIZE_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';
import { transformBToMB } from 'apps/tmr-frontend/src/utils/fileUtil';
import { Controller, useFormContext } from 'react-hook-form';
import MultipleFileInput from '../../../components/inputs/StyledInputs/MultipleFileInput';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';

export type FileFormFields = {
  files: File[];
};

const FILE_FORMATS = [
  '.pdf',
  '.csv',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.xlsb',
  '.xlsm',
  '.zip',
];

const FileForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Controller
        name="files"
        aria-label="file-input"
        control={control}
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <div>
            <MultipleFileInput
              value={value}
              onChange={onChange}
              acceptedFormats={FILE_FORMATS}
              contentClassName={errors['files'] && 'border-red-500'}
              totalLimit={FILE_SIZE_LIMIT}
              totalLimitLabel={`${transformBToMB(FILE_SIZE_LIMIT)} MB`}
            />
            {errors['files'] && (
              <FormErrorMessage
                errorMessage={errors['files'].message as string}
              />
            )}
          </div>
        )}
        rules={{
          required: {
            value: true,
            message: 'File is a required field',
          },
        }}
      />
    </div>
  );
};

export default FileForm;
