import { useMutation } from '@tanstack/react-query';
import { getFileCompleteName } from '../../../../utils/fileUtil';
import { AxiosError } from 'axios';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import { FS_File } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import { deleteFile } from '../../../services/FinancialStatementsService';
import WrapperButton from '../../../components/buttons/WrapperButton';

type RemoveFileProps = {
  file: FS_File;
  folderID: string;
  afterRemoval?: () => void;
};

const RemoveFile = ({ file, folderID, afterRemoval }: RemoveFileProps) => {
  const toast = useRef<Toast>(null);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: () => {
      return deleteFile(file.id, folderID);
    },
    onError: (error: AxiosError) => {
      setConfirmVisible(true);
      setResultVisible(false);
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Remove Folder Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const accepted = () => {
    mutate();
    setResultVisible(true);
  };

  const onResultHide = () => {
    setResultVisible(false);
    if (isSuccess && afterRemoval) {
      afterRemoval();
    }
  };

  return (
    <React.Fragment>
      <WrapperButton
        className="absolute right-0 mr-3"
        onClick={() => setConfirmVisible(true)}
      >
        <i className="pi pi-trash text-17px p-1 border-circle hover:bg-gray-300" />
      </WrapperButton>
      <ConfirmDialog
        visible={confirmVisible}
        onHide={() => setConfirmVisible(false)}
        message={`Do you want to remove the file ${getFileCompleteName(file)}`}
        header="File Removal Confirmation"
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        accept={accepted}
      />
      <Dialog
        header="File Removal in progress"
        visible={resultVisible}
        onHide={onResultHide}
        className="w-fit"
        contentClassName="flex justify-content-center"
      >
        {(isLoading || isError) && <ProgressSpinner className="mx-auto" />}
        {isSuccess && (
          <SuccessFormMessage
            message={`The file ${getFileCompleteName(file)} has been removed!`}
          />
        )}
      </Dialog>
      <ErrorToast toastRef={toast} />
    </React.Fragment>
  );
};

export default RemoveFile;
