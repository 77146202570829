import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchAccountsReceivables } from '../../../services/AccountsReceivablesService';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import ARAgedTable from './ARAgedTable';
import { ARFiltersArgs } from '../ARFilter/ARFilters';
import { useFormContext } from 'react-hook-form';
import { CompanySource } from '../../../Interfaces/User.interfaces';
import ARAgedTableYardi from './ARAgedTableYardi';
import { fetchResidentStatus } from '../../../services/ResidentStatusService';

const ARAgedTableContainer = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<ARFiltersArgs>();
  const filters = watch();

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getAgedAccountsReceivables', selectedCompany, filters],
    queryFn: ({ signal }) => {
      const {
        reportType,
        customers,
        contracts,
        info,
        period,
        ...otherFilters
      } = filters;
      if (selectedCompany?.source === CompanySource.ViewPoint) {
        return fetchAccountsReceivables(
          {
            company: selectedCompany!,
            customers,
            contracts,
            info,
            period,
            ...otherFilters,
          },
          signal
        );
      } else {
        return fetchResidentStatus(
          {
            company: selectedCompany!,
            contracts: contracts,
            customers: customers?.map((c) => c.toString()),
            info: info,
            period: period,
          },
          signal
        );
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  if (isFetching || !selectedCompany)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return selectedCompany.source == CompanySource.ViewPoint ? (
    <ARAgedTable accountsReceivables={data} />
  ) : (
    <ARAgedTableYardi residentsStatus={data} />
  );
});

export default ARAgedTableContainer;
