import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useWatch } from 'react-hook-form';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { EmployeePCReimbursement } from '../../../Interfaces/Accounting/Payroll.interface';
import { fetchPCReimbursement } from '../../../services/PayrollService';
import { ReimbursementFilters } from './PCReimbursementFilters';
import PCReimbursementTable from './PCReimbursementTable';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import PCReimbursementProcess from './PCReimbursementProcess';
import React, { useState } from 'react';
import { formatReimbursementEmployees, updateAttachments } from './utils';
import { FILE_20_MB_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';

const PCReimbursementTableContainer = () => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess } = useRolesAccessContext();
  const [showErrors, setShowErrors] = useState(false);
  const [empData, setEmpData] = useState<EmployeePCReimbursement[]>([]);
  const [fileLimit, setFileLimit] = useState<number>(FILE_20_MB_LIMIT);
  const [reimbursementID, setReimbursementID] = useState<string>();
  const access = rolesAcess?.find(
    (access) => access.report === 'petty_cash_reimbursement'
  );
  const submittedID = useWatch<ReimbursementFilters>({ name: 'submittedID' });

  const { data, isError, isFetching } = useQuery({
    queryKey: ['getPCReimbursement', selectedCompany, submittedID],
    queryFn: ({ signal }) => {
      return fetchPCReimbursement(
        selectedCompany!.id,
        access?.shouldApprove ? submittedID : undefined,
        signal
      );
    },
    onSuccess: (data) => {
      if (data.id) {
        setReimbursementID(data.id);
      } else {
        setReimbursementID(undefined);
      }

      if (data.employees) {
        empData.splice(0);
        empData.push(...formatReimbursementEmployees(data.employees));
        setEmpData(empData);
      } else {
        empData.splice(0);
        empData.push({ empID: null, total: null, details: [{}] });
        setEmpData(empData);
      }

      if (data.attachmentsSize) {
        setFileLimit(FILE_20_MB_LIMIT - data.attachmentsSize);
      } else {
        setFileLimit(FILE_20_MB_LIMIT);
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const updateEmployeesAttachments = (
    employees: EmployeePCReimbursement[],
    newSizeLimit: number,
    id: string
  ) => {
    const newEmps = updateAttachments(employees, empData);

    setEmpData(newEmps);
    setFileLimit(FILE_20_MB_LIMIT - (newSizeLimit ?? 0));
    setReimbursementID(id);
  };

  if (isFetching) {
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );
  }

  return (
    <React.Fragment>
      <PCReimbursementTable
        employees={empData}
        draftID={reimbursementID}
        sizeLimit={fileLimit}
        showErrors={showErrors}
        changeEmployees={(data) => {
          setEmpData(data);
        }}
        changeFileLimit={(size) => {
          setFileLimit(size);
        }}
      />
      <div className="mt-3 flex flex-wrap justify-content-center gap-4 w-fit ml-auto mr-3 printHide">
        <PCReimbursementProcess
          draft={empData}
          approvalMode={!!submittedID}
          defaultDraftID={data?.id}
          isSubmitted={data?.submitted}
          afterCheck={(show) => {
            setShowErrors(show);
          }}
          afterUpdate={updateEmployeesAttachments}
          access={access ?? { report: 'petty_cash_reimbursement' }}
        />
      </div>
    </React.Fragment>
  );
};

export default PCReimbursementTableContainer;
