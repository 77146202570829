import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  EmployeeTimesheet,
  PayrollTimesheetDetails,
  TimeSheetArgs,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { fetchPREmployeesTimesheet } from '../../../services/PayrollService';
import TimesheetTable from './TimesheetTable';
import React, { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { PRTimesheetFilters } from './TimesheetFilters';
import { v4 as uuidv4 } from 'uuid';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import { transformUTCtoLocale } from 'apps/tmr-frontend/src/utils/dateUtils';
import TimesheetProcess from './TimesheetProcess';
import PCReimbursementTable from '../PettyCashReimbursement/PCReimbursementTable';
import { EmployeePCReimbursement } from '../../../Interfaces/Accounting/Payroll.interface';
import { FILE_20_MB_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';
import {
  formatReimbursementEmployees,
  updateAttachments,
} from '../PettyCashReimbursement/utils';
import TimesheetBundleProcess from './TimesheetBundleProcess';
import { formatTimesheetEmployees } from './util';
import TimesheetBundleSteps from './TimesheetBundleSteps';

type TimeSheetTableContainerProps = {
  periodStart: Date;
  periodEnd: Date;
  defaultTimesheet?: TimeSheetArgs;
  defaultLoading?: boolean;
};

const TimeSheetTableContainer = ({
  periodStart,
  periodEnd,
  defaultTimesheet,
  defaultLoading,
}: TimeSheetTableContainerProps) => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess } = useRolesAccessContext();
  const submittedID = useWatch<PRTimesheetFilters>({ name: 'submittedID' });
  const [employees, setEmployees] = useState<EmployeeTimesheet[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [reimbursement, setReimbursement] = useState<EmployeePCReimbursement[]>(
    []
  );
  const [fileLimit, setFileLimit] = useState<number>(FILE_20_MB_LIMIT);
  const [reimbursementID, setReimbursementID] = useState<string>();
  const [useBundleProcess, setUseBundleProcess] = useState(false);
  const [step, setStep] = useState(0);
  const access = rolesAcess?.find(
    (access) => access.report === 'new_pr_timesheet'
  );
  const currentdefTimesheet = useRef(defaultTimesheet);

  const { data, isError } = useQuery({
    queryKey: ['getPREmployeesTimesheet', selectedCompany, submittedID],
    queryFn: ({ signal }) => {
      setIsLoading(true);
      return fetchPREmployeesTimesheet(
        selectedCompany!.id,
        access?.shouldApprove ? submittedID : undefined,
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
    onSuccess: (data) => {
      const reimbursement = data.draft?.reimbursement;
      setEmployees(formatTimesheetEmployees(data.employees));

      if (reimbursement) {
        setReimbursementID(reimbursement.id);
        setUseBundleProcess(true);
        setReimbursement(formatReimbursementEmployees(reimbursement.employees));
        setFileLimit(FILE_20_MB_LIMIT - reimbursement.attachmentsSize);
      } else {
        setReimbursementID(undefined);
        setUseBundleProcess(false);
        setReimbursement([{ empID: null, total: null, details: [{}] }]);
        setFileLimit(FILE_20_MB_LIMIT);
      }

      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const updateEmployeesAttachments = (
    employees: EmployeePCReimbursement[],
    newSizeLimit: number,
    id: string
  ) => {
    const newEmps = updateAttachments(employees, reimbursement);

    setReimbursement(newEmps);
    setFileLimit(FILE_20_MB_LIMIT - (newSizeLimit ?? 0));
    setReimbursementID(id);
  };

  useEffect(() => {
    if (defaultTimesheet && defaultTimesheet !== currentdefTimesheet.current) {
      const newEmpData = employees.map((emp: EmployeeTimesheet) => {
        const defaultEmp = defaultTimesheet.find(
          (defaultEmp) => emp.empID === defaultEmp.empID
        );

        let newDetails: PayrollTimesheetDetails[] | null = null;
        if (defaultEmp?.details) {
          newDetails = defaultEmp.details.map((detail) => {
            return { ...detail, id: uuidv4() };
          });
        }

        return defaultEmp
          ? {
              ...defaultEmp,
              firstName: emp.firstName,
              lastName: emp.lastName,
              details: newDetails ?? undefined,
            }
          : emp;
      });

      setEmployees(formatTimesheetEmployees(newEmpData));
      currentdefTimesheet.current = defaultTimesheet;
    }
  }, [defaultTimesheet, employees]);

  if (
    isLoading ||
    defaultLoading ||
    defaultTimesheet !== currentdefTimesheet.current
  ) {
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );
  }

  return (
    <React.Fragment>
      {useBundleProcess && (
        <div className="mx-3 mb-5 printHide">
          <TimesheetBundleSteps
            index={step}
            changeIndex={(newStep) => {
              setStep(newStep);
            }}
          />
        </div>
      )}
      <span className={`${step !== 0 && 'hidden'}`}>
        <TimesheetTable
          employees={employees}
          periodStart={periodStart}
          periodEnd={periodEnd}
          changeEmployees={(data) => setEmployees(data)}
        />
      </span>
      <span className={`${step !== 1 && 'hidden'}`}>
        <PCReimbursementTable
          employees={reimbursement}
          draftID={reimbursementID}
          sizeLimit={fileLimit}
          showErrors={showErrors}
          changeEmployees={(data) => {
            setReimbursement(data);
          }}
          changeFileLimit={(size) => {
            setFileLimit(size);
          }}
        />
      </span>
      <div className="mt-3 flex flex-wrap justify-content-center gap-4 w-fit ml-auto mr-3 printHide">
        {useBundleProcess ? (
          <TimesheetBundleProcess
            timesheet={employees ?? []}
            reimbursement={reimbursement}
            periodStart={periodStart}
            periodEnd={periodEnd}
            approvalMode={!!submittedID}
            defaultTimesheetID={data?.draft?.id}
            defaultReimbursementID={data?.draft?.reimbursement?.id}
            isSubmitted={data?.draft?.submitted}
            afterCheck={(show) => {
              setShowErrors(show);
            }}
            afterUpdate={updateEmployeesAttachments}
            access={access ?? { report: 'new_pr_timesheet' }}
            step={step}
            changeStep={(newStep) => {
              setStep(newStep);
            }}
          />
        ) : (
          <TimesheetProcess
            draft={employees ?? []}
            periodStart={periodStart}
            periodEnd={periodEnd}
            approvalMode={!!submittedID}
            defaultDraftID={data?.draft?.id}
            isSubmitted={data?.draft?.submitted}
            access={access ?? { report: 'new_pr_timesheet' }}
            changeToBundle={() => {
              setStep(1);
              setUseBundleProcess(true);
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TimeSheetTableContainer;
