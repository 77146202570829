import { useQuery } from '@tanstack/react-query';
import DropdownStyled from 'apps/tmr-frontend/src/app/components/inputs/StyledInputs/DropdownStyled';
import { useCompanyContext } from 'apps/tmr-frontend/src/app/context/CompanyContext';
import { JCJobSummary } from 'apps/tmr-frontend/src/app/Interfaces/Accounting/JobsContracts.interface';
import { fecthJobs } from 'apps/tmr-frontend/src/app/services/JobsContractsService';

type JobProps = {
  value: string;
  showErrors: boolean;
  disabled: boolean;
  changeValue: (job?: string) => void;
  disableFetch?: boolean;
};

const Job = ({
  showErrors,
  disabled,
  value,
  changeValue,
  disableFetch,
}: JobProps) => {
  const { selectedCompany } = useCompanyContext();

  const jobs = useQuery({
    queryKey: ['getPRActiveJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany && !disableFetch,
  });

  const jobItems =
    jobs.data?.map((job: JCJobSummary) => {
      return { ...job, label: `${job.Job} ${job.Description}` };
    }) ?? [];
  const selectedInfo = jobItems?.find((job: JCJobSummary) => job.Job === value);

  return (
    <div className="w-full h-full">
      <DropdownStyled
        options={jobItems}
        labelField="label"
        valueField="Job"
        disabled={disabled}
        isLoading={jobs.isLoading}
        error={jobs.isError}
        className={`h-full printHide ${showErrors && 'p-invalid'}`}
        filter={true}
        value={value}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
        tooltip={`Job or OverHead is required`}
        tooltipOptions={{
          position: 'bottom',
          event: 'hover',
          disabled: !showErrors,
        }}
      />
      <span className="printMedia printShow">{selectedInfo?.label}</span>
    </div>
  );
};

export default Job;
