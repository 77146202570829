import { useQuery } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import ErrorToast, { showToast } from '../../components/messages/ErrorAlert';
import { CompanySummary } from '../../Interfaces/User.interfaces';
import { fetchCompanies } from '../../services/UsersService';
import { CompaniesFilterArgs } from './CompaniesFilter';
import CompaniesTable from './CompaniesTable';

const CompaniesTableContainer = () => {
  const [page, setPage] = useState(0);
  const filters = useWatch<CompaniesFilterArgs>();
  const [backupData, setBackupData] = useState<{
    companies: CompanySummary[];
    totalCompanies: number;
  }>({
    companies: [],
    totalCompanies: 0,
  });
  const [rowsAmount, setRowsAmount] = useState(15);
  const [backupPage, setBackupPage] = useState(0);
  const toast = useRef<Toast>(null);

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getCompanies', page, rowsAmount, filters.source, filters.info],
    queryFn: ({ signal }) => {
      return fetchCompanies(
        {
          page,
          amountPerPage: rowsAmount,
          source: filters.source,
          info: filters.info,
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setBackupData({ companies: data.companies, totalCompanies: data.amount });
      setBackupPage(page);
    },
    onError: (error) => {
      showToast(
        'error',
        toast,
        'Search Companies Error',
        'An error ocurred! please try again later',
        3000
      );
    },
  });

  useEffect(() => {
    setPage(0);
  }, [rowsAmount, filters.source, filters.info]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <CompaniesTable
        companies={data?.companies ?? []}
        totalCompanies={data?.amount ?? 0}
        page={page}
        isFetching={isFetching}
        isError={isError}
        setPage={setPage}
        setRowsAmmount={setRowsAmount}
        rowsAmount={rowsAmount}
        backupData={backupData}
        backupPage={backupPage}
      />
    </React.Fragment>
  );
};

export default CompaniesTableContainer;
