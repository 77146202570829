import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import RegisterForm, {
  RegisterFormFields,
} from '../../../features/user/RegisterForm';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import RemoveUserButton from '../../../features/user/RemoveUserButton';
import SuccessMessage from '../../../features/user/SuccessMessage';
import {
  deleteUser,
  fetchCompany,
  getUser,
  putCompany,
  putUsers,
  usersArgs,
} from '../../../services/UsersService';
import { CompanySource, User } from '../../../Interfaces/User.interfaces';
import LoadingButton from '../../../components/inputs/LoadingButton';
import {
  processAccessForm,
  removeDuplicates,
} from 'apps/tmr-frontend/src/utils/roleUtils';
import { Access } from '../../../Interfaces/Role.interfaces';
import { getRolesInfo, postRoleArgs } from '../../../services/RolesService';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import CompanyForm, {
  CompanyFormFields,
} from '../../../features/companies/CompanyForm';

const CompanyUpdate = () => {
  const navigate = useNavigate();
  const { companyID } = useParams();
  const toast = useRef<Toast>(null);
  const formMethods = useForm<CompanyFormFields>();
  const { handleSubmit, setValue } = formMethods;

  const companyResult = useQuery({
    queryKey: ['getCompany', companyID],
    queryFn: ({ signal }) => {
      return fetchCompany(companyID ?? '', signal);
    },
    onSuccess: (data) => {
      setValue('source', data.source);
      setValue('code', data.code);

      if (data.source === CompanySource.ViewPoint) {
        setValue('hours', data.hours);
        setValue('payPeriods', data.payPeriods);
      } else {
        setValue('name', data.name);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: CompanyFormFields) => {
      return putCompany(companyID ?? '', data);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Update Company Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: CompanyFormFields) => {
    mutate(data);
  };

  if (companyResult.isFetching) {
    return (
      <div className="flex mx-auto">
        <ProgressSpinner />
      </div>
    );
  }

  if (companyResult.error) {
    const error = companyResult.error as AxiosError;
    const errorData = error?.response?.data as { message: string };
    return (
      <div className="pt-8">
        <ErrorMessage
          content={
            errorData.message ||
            'Failed to obtain comapany data! Please try again later.'
          }
        />
      </div>
    );
  }

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-8">
      <ErrorToast toastRef={toast} />
      <div className="text-35px font-bold text-center mb-5 relative">
        <div>Update Company</div>
      </div>
      {!isSuccess && (
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-600px mx-auto flex flex-column gap-5"
          >
            <CompanyForm />
            <div className="flex flex-wrap gap-2 mb-3">
              <Button
                onClick={() => {
                  navigate('/admin/companies');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
              <LoadingButton
                isLoading={isLoading}
                label="Update"
                className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                fontSize="text-22px"
              />
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && (
        <SuccessFormMessage
          message="The company has been updated!"
          redirectUrl="/admin/companies"
          redirectMessage="Click here to return to the companies page"
        />
      )}
    </div>
  );
};

export default CompanyUpdate;
