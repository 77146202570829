import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';
import DropdownStyled from '../../../components/inputs/StyledInputs/DropdownStyled';
import { useQuery } from '@tanstack/react-query';
import {
  fecthCBContractInfo,
  fecthCBContracts,
} from '../../../services/ContractBillingsService';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useFormContext } from 'react-hook-form';
import { NewContractFields } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { Toast } from 'primereact/toast';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import LoadingButton from '../../../components/inputs/LoadingButton';
import { NEW_CONTRACT_FIELDS_TO_IGNORE } from '../formConsts';
import { ARContractResume } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';

type ContractCopyInfoProps = {
  success?: () => void;
};

const ContractCopyInfo = ({ success }: ContractCopyInfoProps) => {
  const { selectedCompany } = useCompanyContext();
  const { setValue, clearErrors, reset, getValues } =
    useFormContext<NewContractFields>();
  const [selectedContract, setSelectedContract] = useState<string>();
  const [showDialog, setShowDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const toast = useRef<Toast>(null);

  const hide = () => {
    setShowDialog(false);
    setShowError(false);
    setSelectedContract(undefined);
  };

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getActiveContracts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthCBContracts({ companyID: selectedCompany!.id, status: 1 }, signal),
    refetchOnWindowFocus: false,
  });

  const copyRequest = useQuery({
    queryKey: ['getContractData', selectedCompany, selectedContract],
    queryFn: ({ signal }) =>
      fecthCBContractInfo(selectedCompany!.id, selectedContract || '', signal),
    onSuccess: (data) => {
      const contractNumber = getValues('contract');
      reset();
      clearErrors();

      const { retainage, contract, ...otherFields } = data;
      for (let ignoreKey of NEW_CONTRACT_FIELDS_TO_IGNORE) {
        delete otherFields[ignoreKey];
      }

      setValue('contract', contractNumber);
      const keys = Object.keys(otherFields);
      for (let key of keys) {
        const val = otherFields[key];
        if (val != null) {
          setValue(key as keyof NewContractFields, val);
        }
      }

      setValue('retainage', retainage * 100);

      if (success) {
        success();
      }

      hide();
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Copy Contract Info',
        'An error ocurred! please try again later',
        3000
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <ConfirmationDialog
        tagKey="contract-copy"
        visibility={showDialog}
        Button={
          <Button
            className="w-19rem buttonSecondary border-0 flex py-3"
            onClick={() => {
              setShowDialog(true);
            }}
            disabled={copyRequest.isFetching}
          >
            <span className="mx-auto text-22px text-white">
              Copy Existing Contract
            </span>
          </Button>
        }
        contentClassName="pb-0"
        onConfirm={() => {}}
        icon="hidden"
        acceptClassName="hidden"
        rejectClassName="hidden"
        onHide={() => hide()}
        message={
          <div className="mr-3">
            <div className="mb-1">
              Are you sure you want to overwrite the form?
            </div>
            <div>
              <label htmlFor="contract-dp" className="text-standard block mb-2">
                Please select the contract to copy:
              </label>
              <DropdownStyled
                id={'contract-dp'}
                error={showError}
                onChange={(e) => {
                  const value = e.value;

                  if (value) {
                    setShowError(false);
                  }

                  setSelectedContract(value);
                }}
                value={selectedContract}
                options={data?.map((contract: ARContractResume) => ({
                  Contract: contract.Contract,
                  Label: `${contract.Contract} ${contract.Description}`,
                }))}
                labelField="Label"
                valueField="Contract"
                placeholder={
                  isError ? 'Failed to load contracts!' : 'Select Contract'
                }
                isDisabled={isError}
                isLoading={isFetching}
                clearable={true}
                filter={true}
                resetFilterOnHide={true}
              />
              {showError && (
                <FormErrorMessage errorMessage="Contract is required" />
              )}
            </div>
            <div className="flex justify-content-end gap-2 mt-5 pb-2">
              <Button
                onClick={() => {
                  hide();
                }}
                className="blackText font-bold transparent border-none hover:bg-gray-200 active:bg-gray-200 active:text-black-alpha-90 hover:text-black-alpha-90"
              >
                No
              </Button>
              <LoadingButton
                className="bluwaiBlue font-bold border-none mr-2"
                onClick={() => {
                  if (!selectedContract) {
                    setShowError(true);
                  } else {
                    copyRequest.refetch();
                  }
                }}
                label="Yes"
                isLoading={copyRequest.isFetching}
                fontSize="text-base"
              />
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

export default ContractCopyInfo;
