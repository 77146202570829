import { useQuery } from '@tanstack/react-query';
import DropdownStyled from 'apps/tmr-frontend/src/app/components/inputs/StyledInputs/DropdownStyled';
import { useCompanyContext } from 'apps/tmr-frontend/src/app/context/CompanyContext';
import { JCPhase } from 'apps/tmr-frontend/src/app/Interfaces/Accounting/JobsContracts.interface';
import {
  fetchJCJobBonusPhases,
  fetchJCJobPhases,
} from 'apps/tmr-frontend/src/app/services/JobsContractsService';

type PhaseProps = {
  value: string;
  job?: string;
  showErrors: boolean;
  changeValue: (phase?: string) => void;
  searchBonus?: boolean;
};

const Phase = ({
  value,
  job,
  showErrors,
  changeValue,
  searchBonus,
}: PhaseProps) => {
  const { selectedCompany } = useCompanyContext();

  const phases = useQuery({
    queryKey: ['getJCJobPhases', selectedCompany, job, searchBonus],
    queryFn: ({ signal }) => {
      if (searchBonus) {
        return fetchJCJobBonusPhases(selectedCompany!.id, job ?? '');
      }

      return fetchJCJobPhases(
        {
          companyID: selectedCompany!.id,
          job,
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job),
  });

  const selectedInfo = phases.data?.find((job: JCPhase) => job.Phase === value);

  return (
    <div className="w-full h-full">
      <DropdownStyled
        options={phases.data}
        labelField="Label"
        valueField="Phase"
        isLoading={phases.isLoading}
        error={phases.isError}
        className={`h-full  printHide ${showErrors && 'p-invalid'}`}
        filter={true}
        value={value}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
        tooltip={`PhaseCode is required`}
        tooltipOptions={{
          position: 'bottom',
          event: 'hover',
          disabled: !showErrors,
        }}
      />
      <span className="printMedia printShow">{selectedInfo?.Label}</span>
    </div>
  );
};

export default Phase;
