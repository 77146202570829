import { AxiosResponse } from 'axios';
import { FS_File } from '../app/Interfaces/Accounting/FinancialStatements.interface';

export const getFilesTotalSize = (files: File[]) => {
  let size = 0;
  files.forEach((file) => {
    size += file.size;
  });

  return size;
};

export const downloadFile = (response: AxiosResponse) => {
  const contentDisposition = response.headers['content-disposition'];
  const defaultFileName = 'file';
  const fileName = contentDisposition
    ? decodeURIComponent(contentDisposition.split('filename=')[1])
    : defaultFileName;

  const blob = new Blob([response.data]);

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getFileCompleteName = (file: FS_File) => {
  let filename = file.name;

  if (file.extension) {
    filename = `${filename}.${file.extension}`;
  }

  return filename;
};

export const transformBToMB = (size: number) => {
  const sizeMB = size / 1048576;
  const roundedMB = Math.round(sizeMB * 10000) / 10000;

  return roundedMB;
};

export const formatBytesSize = (size: number) => {
  if (size >= 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  } else if (size >= 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${size} B`;
  }
};

export const downloadFileUsingPath = (path: string) => {
  const a = document.createElement('a');
  a.href = path;
  a.click();
};

export const EXCEL_FORMATS = ['.xls', '.xlsx', '.xlsb', '.xlsm'];

export const WORD_FORMATS = ['.doc', '.docx'];

export const FILE_FORMATS = [
  ...EXCEL_FORMATS,
  ...WORD_FORMATS,
  '.pdf',
  '.csv',
  '.zip',
];
