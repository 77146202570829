import { Steps, StepsSelectParams } from 'primereact/steps';

type TimesheetBundleSteps = {
  index: number;
  changeIndex: (number: number) => void;
};

const TimesheetBundleSteps = ({ index, changeIndex }: TimesheetBundleSteps) => {
  const items = [
    {
      label: 'Timesheet',
      command: (event: StepsSelectParams) => {
        changeIndex(event.index);
      },
    },
    {
      label: 'Petty Cash Reimbursement',
      command: (event: StepsSelectParams) => {
        changeIndex(event.index);
      },
    },
  ];

  return (
    <Steps
      model={items}
      activeIndex={index}
      readOnly={false}
      className="steps-db-bg"
    />
  );
};

export default TimesheetBundleSteps;
