import { Column } from 'primereact/column';
import {
  DataTablePFSEvent,
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { CompanySummary } from '../../Interfaces/User.interfaces';
import { animateCellText } from '../../../utils/htmlUtils';
import { useCallback } from 'react';

type CompaniesTableProps = {
  companies: CompanySummary[];
  totalCompanies: number;
  rowsAmount: number;
  page: number;
  isFetching: boolean;
  isError: boolean;
  backupData?: { companies: CompanySummary[]; totalCompanies: number };
  backupPage?: number;
  setPage: (page: number) => void;
  setRowsAmmount: (rows: number) => void;
};

const CompaniesTable = ({
  companies,
  totalCompanies,
  rowsAmount,
  page,
  isFetching,
  isError,
  backupData,
  backupPage,
  setPage,
  setRowsAmmount,
}: CompaniesTableProps) => {
  const navigate = useNavigate();

  const onPage = (event: DataTablePFSEvent) => {
    setPage(event.page ?? 0);
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 61;
    const rowHeight = 36;
    return headerHeight + footerHeight + rows * rowHeight + 2;
  };

  const onRowClick = (event: DataTableRowClickEventParams) => {
    navigate(event.data.id);
  };

  return (
    <Table
      data={isError ? backupData?.companies ?? [] : companies}
      first={(isError ? backupPage ?? 0 : page) * rowsAmount}
      className={`tableStyled tablePrint`}
      lazy
      paginator
      loading={isFetching}
      totalRecords={isError ? backupData?.totalCompanies : totalCompanies}
      onPage={onPage}
      calcHeight={calcHeight}
      onRowsAmountChange={setRowsAmmount}
      onRowMouseEnter={useCallback(onRowHover, [])}
      onRowClick={onRowClick}
      rowClass={() => 'cursor-pointer'}
    >
      <Column
        field="code"
        header="Code"
        style={{ minWidth: '150px' }}
        headerClassName={`tableHeader font-normal`}
        className={`text-standard justify-content-center blackText tableCell`}
        footerClassName="tableFooter"
      />
      <Column
        field="name"
        header="Name"
        body={(company) => {
          return <div className="scroll-text">{company.name}</div>;
        }}
        style={{ minWidth: '150px' }}
        headerClassName={`tableHeader font-normal justify-content-center`}
        className={` text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
        footerClassName="tableFooter"
      />
      <Column
        field="source"
        header="Source"
        style={{ minWidth: '350px' }}
        headerClassName={`tableHeader font-normal`}
        className={` justify-content-center text-standard blackText tableCell`}
        footerClassName="tableFooter"
      />
    </Table>
  );
};

export default CompaniesTable;
