import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import WrapperButton from '../../../components/buttons/WrapperButton';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';
import { useCompanyContext } from '../../../context/CompanyContext';
import { JobCostCode } from '../../../Interfaces/Accounting/ACForms.interfaces';
import {
  fetchJobExtras,
  fetchPhaseMasterCostCodes,
} from '../../../services/ACFormsService';
import { JobPhasesCostCode, JobPhasesFormFields } from './JobPhasesForm';

type JobPhaseRow = {
  index: number;
  addRow: (row?: JobPhasesCostCode) => void;
  removeRow: (index: number) => void;
};

const JobPhaseRow = ({ index, addRow, removeRow }: JobPhaseRow) => {
  const { selectedCompany } = useCompanyContext();
  const { setValue, register, unregister, getValues } =
    useFormContext<JobPhasesFormFields>();
  const [job, costCodes, costCode] = useWatch({
    name: ['job', 'costCodes', `costCodes.${index}`],
  });

  const costCodesRequest = useQuery({
    queryKey: ['getJCPhaseMasterCostCodes', selectedCompany],
    queryFn: ({ signal }) => {
      return fetchPhaseMasterCostCodes(selectedCompany!.id, signal);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getJobExtras', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobExtras(selectedCompany!.id, job, signal);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const validate = () => {
    const costCodes = getValues(`costCodes`);
    const costCodeRow = costCodes[index];

    const elementIndex = costCodes?.findIndex(
      (costCode) =>
        costCode.extra === costCodeRow.extra &&
        costCode.costCode === costCodeRow.costCode
    );

    return elementIndex === index ? true : 'Repeated pair Cost Code - Extra';
  };

  const validateCB = useCallback(validate, [index, getValues]);

  useEffect(() => {
    const code: JobCostCode = costCodesRequest.data?.find(
      (code: JobCostCode) => code.CostCode === costCode?.costCode
    );

    if (code) {
      setValue(`costCodes.${index}.description`, code.Description);
    }
  }, [costCode?.costCode, costCodesRequest.data]);

  useEffect(() => {
    unregister(`costCodes.${index}.extra`, {
      keepValue: true,
    });
    if (data?.length > 0 || isFetching) {
      register(`costCodes.${index}.extra`, {
        required: {
          value: true,
          message: 'Extra is a required field',
        },
        maxLength: undefined,
        minLength: undefined,
        validate: validateCB,
      });
    } else {
      register(`costCodes.${index}.extra`, {
        required: {
          value: true,
          message: 'Extra is a required field',
        },
        maxLength: { value: 2, message: 'Extra max length is 2' },
        minLength: { value: 2, message: 'Extra min length is 2' },
        value: '00',
        validate: validateCB,
      });
    }
  }, [data, index, validateCB, isFetching]);

  return (
    <div className="grid mx-1">
      <div className="col-12 sm:col-11 flex flex-wrap pb-0 sm:pr-0 sm:pb-2">
        <div className="col-12 sm:col-4">
          <label
            htmlFor={`costCodes.${index}.costCode`}
            className="text-standard block mb-2"
          >
            Cost Code
          </label>
          <FormControlledDropdown
            isFieldArray={true}
            virtualScrollerOptions={{ itemSize: 38 }}
            formID={`costCodes.${index}.costCode`}
            options={costCodesRequest.data?.map((code: JobCostCode) => ({
              ...code,
              label: `${code.CostCode} - ${code.Description ?? ''}`,
            }))}
            labelField="label"
            valueField="CostCode"
            placeholder={
              costCodesRequest.isError ? 'Failed to load options' : 'Select'
            }
            isDisabled={costCodesRequest.isError}
            isLoading={costCodesRequest.isFetching}
            clearable={false}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Cost Code is a required field',
              },
              validate: validateCB,
            }}
            ignoreOnBlur={true}
          />
        </div>
        <div className="col-12 sm:col-4">
          <label
            htmlFor={`costCodes.${index}.description`}
            className="text-standard block mb-2"
          >
            Phase Description
          </label>
          <FormControlledInput
            isFieldArray={true}
            defaultValue=""
            formID={`costCodes.${index}.description`}
            autocomplete="off"
            rules={{
              required: {
                value: true,
                message: 'Phase Description is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-4">
          <label className="text-standard block mb-2" htmlFor={`extra`}>
            Extra/Location
          </label>
          {data?.length > 0 || isFetching ? (
            <FormControlledDropdown
              isFieldArray={true}
              formID={`costCodes.${index}.extra`}
              options={data}
              labelField="ExDesc"
              valueField="ExNumber"
              isLoading={isFetching}
              placeholder={isError ? 'Failed to load extras!' : 'Select'}
              isDisabled={isError}
              clearable={false}
              filter={true}
              resetFilterOnHide={true}
              ignoreOnBlur={true}
            />
          ) : (
            <FormControlledInput
              isFieldArray={true}
              formID={`costCodes.${index}.extra`}
              keyfilter="pnum"
              autocomplete="off"
            />
          )}
        </div>
      </div>
      <div className="col-12 sm:col-1 flex flex-wrap pt-0 sm:pl-0 sm:pt-2">
        <div className="col-4 flex sm:mt-1">
          <WrapperButton
            onClick={() => addRow(costCode)}
            className="h-fit mx-auto sm:mt-5"
            type="button"
          >
            <i className="pi pi-refresh text-17px p-1 border-circle hover:bg-gray-300" />
          </WrapperButton>
        </div>
        <div className="col-4 flex sm:mt-1">
          {costCodes?.length > 1 && (
            <WrapperButton
              onClick={() => removeRow(index)}
              className="h-fit mx-auto sm:mt-5"
              type="button"
            >
              <i className="pi pi-trash text-17px p-1 border-circle hover:bg-gray-300" />
            </WrapperButton>
          )}
        </div>
        {costCodes?.length - 1 === index && (
          <div className="col-4 flex sm:mt-1">
            <WrapperButton
              onClick={() => addRow()}
              className="h-fit mx-auto sm:mt-5"
              type="button"
            >
              <i className="pi pi-plus text-17px p-1 border-circle hover:bg-gray-300" />
            </WrapperButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobPhaseRow;
